@font-face {
  font-family: 'MallasGalbis';
  src:  url('../fonts/icofont/MallasGalbis.eot?lcko1a');
  src:  url('../fonts/icofont/MallasGalbis.eot?lcko1a#iefix') format('embedded-opentype'),
    url('../fonts/icofont/MallasGalbis.ttf?lcko1a') format('truetype'),
    url('../fonts/icofont/MallasGalbis.woff?lcko1a') format('woff'),
    url('../fonts/icofont/MallasGalbis.svg?lcko1a#MallasGalbis') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

i[class^="icon-"], i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MallasGalbis' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e913";
  color: #f4d026;
}
.icon-instagram:before {
  content: "\e914";
  color: #f4d026;
}
.icon-linkedin:before {
  content: "\e915";
  color: #f4d026;
}
.icon-twitter:before {
  content: "\e916";
  color: #f4d026;
}
.icon-youtube:before {
  content: "\e917";
  color: #f4d026;
}
.icon-abajo:before {
  content: "\e910";
  color: #5a738b;
}
.icon-chevron-right:before {
  content: "\e911";
  color: #01579b;
}
.icon-lupa:before {
  content: "\e912";
  color: #88a2bb;
}
.icon-abajo-menu:before {
  content: "\e900";
}
.icon-abajo-seo:before {
  content: "\e901";
}
.icon-ArrowRight:before {
  content: "\e902";
}
.icon-asesoramiento-online:before {
  content: "\e903";
  color: #5a738b;
}
.icon-box:before {
  content: "\e904";
  color: #fff;
}
.icon-carro:before {
  content: "\e905";
  color: #5a738b;
}
.icon-Check:before {
  content: "\e906";
}
.icon-CheckCircle:before {
  content: "\e907";
}
.icon-devolucion-facil:before {
  content: "\e908";
  color: #5a738b;
}
.icon-envios-rapidos:before {
  content: "\e909";
  color: #5a738b;
}
.icon-house:before {
  content: "\e90a";
  color: #999;
}
.icon-pago-seguro:before {
  content: "\e90b";
  color: #5a738b;
}
.icon-pdf-file:before {
  content: "\e90c";
  color: #01579b;
}
.icon-user:before {
  content: "\e90d";
}
.icon-UserCirclePlus:before {
  content: "\e90e";
}
.icon-WhatsApp:before {
  content: "\e90f";
  color: #fff;
}
